//菜单列表
const getMenuListUrl = `/gateway/blade-system/menu/list`;
//详情
const getMenuDetailUrl = `/gateway/blade-system/menu/detail`;
//新增or修改
const postMenuSubmitUrl = `/gateway/blade-system/menu/submit`;
//删除
const deleteMenuUrl = `/gateway/blade-system/menu/remove`;
export {
  getMenuListUrl,
  getMenuDetailUrl,
  postMenuSubmitUrl,
  deleteMenuUrl
};
