import { mapHelper } from "@/utils/common";
// 模块
const moduleList = [
  {
    label: "管理后台",
    value: 0,
  },
  {
    label: "驾驶舱",
    value: 1,
  },
  {
    label: "物联网平台",
    value: 2,
  },
  {
    label: "三维资产引擎",
    value: 3,
  },
  {
    label: "数据分析平台",
    value: 4,
  },
  {
    label: "公共能力中心",
    value: 6,
  },
];
const { map: moduleListMap, setOps: moduleListMapOps } =
  mapHelper.setMap(moduleList);
// 菜单状态
const menuStatus = [
  {
    label: "正常",
    value: 0,
  },
  {
    label: "删除",
    value: 1,
  },

];
const { map: menuStatusMap, setOps: menuStatusOps } =
  mapHelper.setMap(menuStatus);
// 菜单是否隐藏
const isHideMenu = [
  {
    label: "是",
    value: 1,
  },
  {
    label: "否",
    value: 0,
  },

];
const { map: isHideMenuMap, setOps: isHideMenuOps } =
  mapHelper.setMap(isHideMenu);
// 菜单类型
const menuCategory = [
  {
    label: "菜单",
    value: 1,
  },
  {
    label: "按钮",
    value: 2,
  },

];
const { map: menuCategoryMap, setOps:menuCategoryOps } =
  mapHelper.setMap(menuCategory);
  // 菜单是否隐藏
const isOpen = [
  {
    label: "是",
    value: 1,
  },
  {
    label: "否",
    value: 0,
  },

];
const { map: isOpenMap, setOps: isOpenOps } =
  mapHelper.setMap(isOpen);
  export {
  moduleListMap,
  moduleListMapOps,
  menuStatusMap,
  menuStatusOps,
  isHideMenuMap,
  isHideMenuOps,
  menuCategoryMap,
  menuCategoryOps,
  isOpenOps
};