<template>
  <div class="menuManage">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增一级菜单" @click="addMenu(1)"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="moduleListMapOps"
          label="模块归属"
          placeholder="请选择"
          v-model="searchParam.domain"
        />
        <v-input
          clearable
          label="菜单名称"
          placeholder="请输入"
          v-model="searchParam.name"
        />
        <v-select
          clearable
          :options="menuStatusOps"
          label="菜单状态"
          placeholder="请选择"
          v-model="searchParam.isDeleted"
        />
        <v-select
          clearable
          :options="isHideMenuOps"
          label="菜单隐藏"
          placeholder="请选择"
          v-model="searchParam.hidden"
        />
      </template>
      <template #operateSlot="{ row }">
        <v-button text="编辑" type="text" @click="edit(row)" />
        <v-button
          v-if="row.isParentMenu"
          text="新增子菜单"
          type="text"
          @click="addMenu(2, row)"
        />
        <v-button text="删除" type="text" @click="deleteMenu(row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getMenuListUrl, deleteMenuUrl } from "./api.js";
import { moduleListMapOps, menuStatusOps, isHideMenuOps } from "./map.js";
import { createImgVNode } from "@/utils/utils.js";
export default {
  name: "menuManage",
  data() {
    return {
      moduleListMapOps: moduleListMapOps(), //模块
      menuStatusOps: menuStatusOps(), //菜单状态数据
      isHideMenuOps: isHideMenuOps(), //菜单是否隐藏
      searchParam: {},
      tableUrl: getMenuListUrl,
      headers: [
        {
          prop: "domainName",
          label: "模块归属",
        },
        {
          prop: "isParentMenu",
          label: "上级菜单",
          formatter: (row, prop) => {
            return row[prop] ? "有" : "无";
          },
        },
        {
          prop: "name",
          label: "菜单名称",
        },
        {
          prop: "source",
          label: "菜单图标",
          formatter: (row, prop) => createImgVNode(this, row, prop, { imgUrl: row[prop] ? row[prop].split('|')[0] : '' }),
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    //编辑
    edit(row) {
      // this.$axios.get(`${getMenuDetailUrl}?id=${row.id}`).then((res) => {
      //   console.log("res----->", res);
      // });
      this.$router.push({
        name: "addMenu",
        query: {
          id: row.id,
          domainName: row.domainName,
          isEdit: true,
        },
      });
    },
    // 新增子菜单
    addMenu(type, row) {
      if (type == 1) {
        this.$router.push({
          name: "addMenu",
        });
      } else {
        this.$router.push({
          name: "addMenu",
          query: {
            id: row.id,
            domainName: row.domainName,
            isEdit: false,
          },
        });
      }
    },
    //删除
    deleteMenu(row) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          ids: row.id,
          roleId: this.$store.state.app.userInfo.roleId,
        };
        this.$axios.post(`${deleteMenuUrl}`, params).then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.$refs.list.getList();
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.menuManage {
  box-sizing: border-box;
  height: 100%;
}
</style>
